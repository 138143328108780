<template>
  <b-card
    title="Edit Volunteer"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="form"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="NIJ"
              label-for="nij"
            >
              <b-form-input
                id="nij"
                v-model="nij"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Phone"
              label-for="cellphone"
            >
              <b-form-input
                id="cellphone"
                v-model="cellphone"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="CG"
              label-for="cg"
            >
              <b-form-input
                id="cg"
                v-model="cg"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="CG Presence Percentage"
              label-for="cg_presence"
            >
              <span v-if="presenceResult === '-'">
                -
              </span>
              <span v-else>
                <span
                  v-for="item in presenceResult"
                  :key="item"
                >
                  <span
                    v-if="item === '-'"
                    style="margin-right: 5px;"
                  >
                    -
                  </span>
                  <b-badge
                    v-else
                    :class="cgPresenceResult(Number(item))"
                    style="margin-right: 5px;"
                  >
                    {{ Math.round(Number(item)) }}%
                  </b-badge>
                </span>
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ministry"
              label-for="ministry"
            >
              <b-form-input
                id="ministry"
                v-model="ministry"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Churches"
              label-for="churches"
            >
              <validation-provider
                #default="{ errors }"
                name="Churches"
                rules="required"
              >
                <v-select
                  v-model="churches"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="churchOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Position"
              label-for="position"
            >
              <validation-provider
                #default="{ errors }"
                name="Position"
                rules="required"
              >
                <v-select
                  v-model="position"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="positionOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Division"
              label-for="divisions"
            >
              <validation-provider
                #default="{ errors }"
                name="divisions"
                rules="required"
              >
                <v-select
                  v-model="divisions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="divisionOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-for="status"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="statusOptions"
                  @input="onStatusChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Start date"
              label-for="start_date"
            >
              <validation-provider
                #default="{ errors }"
                name="Start date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="start_date"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="showStatus"
            cols="12"
          >
            <b-form-group
              label="End Date"
              label-for="end_date"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="end_date"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="showStatus"
            cols="12"
          >
            <b-form-group
              label="End Reason"
              label-for="end_reason"
            >
              <b-form-input
                id="end_reason"
                v-model="end_reason"
                placeholder="End Reason"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'volunteers' }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormDatepicker, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Cookies from 'js-cookie'
// import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormDatepicker,
    BBadge,

    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      nij: '',
      name: '',
      cellphone: '',
      cg: '',
      presenceResult: '',
      ministryId: '',
      ministry: '',
      churches: {},
      churchOptions: [],
      position: [],
      positionOptions: [
        {
            name: 'LEADER',
        },
        {
            name: 'MEMBER',
        },
      ],
      status: [],
      statusOptions: [
        {
          id: -1,
          name: 'Non active',
        },
        {
          id: 0,
          name: 'Need rereg',
        },
        {
          id: 1,
          name: 'Need verification for reregistration',
        },
        {
          id: 2,
          name: 'Active',
        },
      ],
      divisions: {},
      divisionOptions: [],
      start_date: null,
      end_date: null,
      end_reason: null,
      showStatus: false,
      // status: 0,
      required,
    }
  },
  mounted() {
    // Get user data
    // const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    // const userChurches = userData[0].churches.map(elem => elem).join(',')

    // Get the volunteer ldata
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/${this.$route.params.id}`)
      .then(volunteer => {
        this.nij = volunteer.data.nij
        this.name = volunteer.data.name
        this.cellphone = volunteer.data.cell_phone
        this.cg = volunteer.data.cg
        this.presenceResult = volunteer.data.attend_percentage.split(',')
        this.ministry_id = volunteer.data.ministry_id
        this.ministry = volunteer.data.ministry
        this.position.push({ name: volunteer.data.position })
        this.end_date = `${volunteer.data.end_year}-${volunteer.data.end_month}-${volunteer.data.end_date}`
        this.start_date = `${volunteer.data.start_year}-${volunteer.data.start_month}-${volunteer.data.start_date}`

        let statusText = 'Need rereg'
        if (volunteer.data.status === 1) statusText = 'Need verification for reregistration'
        else if (volunteer.data.status === 2) statusText = 'Active'
        if (this.end_date && volunteer.data.status === 0) {
          this.showStatus = true
          this.status.push({ id: -1, name: 'Non active' })
        } else {
          this.status.push({ id: volunteer.data.status, name: statusText })
        }

        // Churches
        if (volunteer.data.couchdb_id) {
          const couchdbIds = volunteer.data.couchdb_id
          const churches = volunteer.data.church

          this.churches = { id: couchdbIds, name: churches }
        }
        // Get the church list
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/churches`)
          .then(churches => {
            churches.data.forEach(church => {
              this.churchOptions.push({ id: church.couchdbId, name: church.name })
            })
          })

        // Hide loading
        this.isLoading = false

        // If the division valid
        if (volunteer.data.division) {
          this.divisions = { id: volunteer.data.division_id, name: volunteer.data.division }
          // this.divisions.push({ id: volunteer.data.division_id, name: volunteer.data.division })
        }
        // Get the division list
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${this.ministry_id}`)
          .then(churches => {
            churches.data.forEach(division => {
              this.divisionOptions.push({ id: division.id, name: division.name })
            })
          })
      })
  },
  methods: {
    onStatusChanged(status) {
      if (status.id === -1) {
        this.showStatus = true
      } else {
        this.end_date = null
        this.showStatus = false
      }
    },
    cgPresenceResult(result) {
      let badge = 'badge-danger'
      if (result >= 81.25) {
        badge = 'badge-success'
      } else if (result >= 56.25) {
        badge = 'badge-info'
      } else if (result >= 31.25) {
        badge = 'badge-warning'
      }

      return badge
    },
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('update', 'volunteers')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Set the role data
          // if (this.end_year) this.status = 0
          const data = {
            churchId: this.churches.id,
            divisionId: this.divisions.id,
            position: this.position.name,
            startDate: Number(this.start_date.split('-')[2]),
            startMonth: Number(this.start_date.split('-')[1]),
            startYear: this.start_date.split('-')[0],
            endDate: this.end_date ? Number(this.end_date.split('-')[2]) : null,
            endMonth: this.end_date ? Number(this.end_date.split('-')[1]) : null,
            endYear: this.end_date ? this.end_date.split('-')[0] : null,
            endReason: this.end_reason,
            status: this.status.id === -1 ? 0 : this.status.id,
            updatedAt: new Date(),
          }

          // Update volunteer table
          await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/${this.$route.params.id}`, data)

          // Redirect to role lists
          this.isLoading = false
          this.$router.replace({ name: 'volunteers' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Data has been updated successfully.',
                },
              })
            })
        }
      })
    },
  },
}
</script>